<template>
  <CRow>
    <CCol sm="12">
      <CCard v-if="restaurant">
        <CCardHeader>
          <CIcon name="cil-dinner" />
          <h5 class="d-inline ml-2">
            <h3 class="d-inline">
              <CBadge color="secondary">{{ restaurant.restaurant_name }}</CBadge>
            </h3>
            Linked Menu Items
          </h5>
          <div class="card-header-actions d-flex flex-gap-2">
            <CButton v-if="restaurantId" color="link" :to="{ name: 'View Restaurant', params: { 'id': restaurantId } }">
              <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back To Restaurant
            </CButton>

            <CButton color="success" @click="$store.commit('set', ['bulkMenuUpdateShow', true])">
              <CIcon name="cil-layers" class="align-bottom" />Bulk <strong>Update</strong>
            </CButton>

            <CButton color="primary" :to="{ name: 'AddNewLinkedMenu' }">
              <CIcon name="cil-plus" class="align-bottom" /><strong>Add New</strong> Linked Menu
            </CButton>

            <CButton color="dark" :to="prevRoute">
              <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
            </CButton>
          </div>
        </CCardHeader>

        <BackendTable :usetoprice="restaurant.usetoprice" ref="linkedMenuTable" />

        <BulkMenuUpdate :restaurant="{ id: restaurantId, usetoprice: restaurant.usetoprice }" @updated="menusUpdated" />
      </CCard>

      <div v-else>
        <CSpinner v-if="loading" color="primary" />
        <h6 v-else>Restaurant not found!</h6>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import BackendTable from "./BackendTable";
import BulkMenuUpdate from "@/components/BulkMenuUpdate";

export default {
  name: "RestaurantLinkedMenus",
  components: {
    BackendTable,
    BulkMenuUpdate,
  },
  data() {
    return {
      restaurant: null,
      loading: true,

      // Previous page
      prevRoute: null,
    };
  },

  // Get previous page
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  beforeRouteLeave: function (to, from, next) {
    // Indicate to the SubComponent (BackendTable) that we are leaving the route
    // Make sure to always call the next function, otherwise the hook will never be resolved
    // Ref: https://router.vuejs.org/en/advanced/navigation-guards.html
    next(this.$refs.linkedMenuTable.prepareToExit());
  },

  async mounted() {
    await this.getRestaurant();
  },
  computed: {
    restaurantId() { return this.$route.params.restaurant_id; },
  },
  methods: {
    async getRestaurant() {
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_NAME.replace("{id}", this.restaurantId), {
          params: {
            fields: 'usetoprice'
          }
        })
        .then((response) => {
          this.restaurant = response.data;
        })
        .finally(() => this.loading = false);
    },

    menusUpdated(result) {
      // result: { "message": "", "data": [] }
      this.$refs.linkedMenuTable.getAllItems()
    }
  },
};
</script>
