var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCardBody',[_c('alert-section',{attrs:{"successAlertMessage":_vm.successAlertMessage,"dismissSecs":_vm.dismissSecs,"dismissSuccessAlert":_vm.dismissSuccessAlert,"errorAlertMessage":_vm.errorAlertMessage,"showErrorAlert":_vm.showErrorAlert}}),_c('CCard',{staticClass:"filters",attrs:{"accent-color":"warning"}},[_c('CCardHeader',{staticClass:"text-warning shadow-none card-header",attrs:{"role":"button"},on:{"click":_vm.resetFilters}},[_c('strong',{staticClass:"m-0"},[_c('CIcon',{attrs:{"name":`cil-filter${_vm.$store.state.filterShow ? '-x' : ''}`}}),_vm._v(" Filters")],1),_c('div',{staticClass:"card-header-actions"},[_c('CIcon',{attrs:{"name":`cil-chevron-${_vm.$store.state.filterShow ? 'bottom' : 'top'}`}})],1)]),_c('CCollapse',{attrs:{"show":_vm.$store.state.filterShow}},[_c('CCardBody',{staticClass:"p-2"},[_c('div',{staticClass:"d-flex flex-md-row flex-column",staticStyle:{"gap":"0.5rem"}},[_c('div',{staticClass:"flex-grow-1"},[_c('v-select',{staticClass:"v-select-filter",attrs:{"placeholder":"Select category..","options":_vm.availableCategories},on:{"input":_vm.categoryFilter},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1),(_vm.pages > 1)?_c('div',[_c('CPagination',{attrs:{"align":"end","dots":false,"pages":_vm.pages,"active-page":_vm.activePage},on:{"update:activePage":function($event){_vm.activePage=$event},"update:active-page":function($event){_vm.activePage=$event}}})],1):_vm._e()])])],1)],1),(_vm.usetoprice)?_c('CCard',[_c('CCardBody',{staticClass:"p-2"},[_c('CInputCheckbox',{attrs:{"label":"Same price toprice","checked":_vm.samepricetoprice,"custom":""},on:{"update:checked":function($event){_vm.samepricetoprice=$event}}})],1)],1):_vm._e(),_c('CDataTable',{attrs:{"size":"sm","hover":"","striped":"","items":_vm.loadedItems,"fields":_vm.fields,"sorter":{ external: true, resetable: true },"table-filter":{
      label: 'Filter:',
      placeholder: '...',
      external: true,
      lazy: true
    },"column-filter":{ external: true, lazy: true },"itemsPerPageSelect":{
      external: true,
      values: [5, 15, 25, 50, 100, 250, 500],
    },"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"noItemsView":{
      noResults: 'No filtering results are available!',
      noItems: 'No linked menu items found!',
    }},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sorter-value":_vm.sorterValue,"update:table-filter-value":_vm.tableSearch,"update:column-filter-value":_vm.columnFilter,"pagination-change":_vm.paginationChange},scopedSlots:_vm._u([{key:"move_index",fn:function({ item }){return [_c('td',{staticClass:"move-item text-center",on:{"click":_vm.getSortingAlert}},[_c('CIcon',{staticClass:"align-bottom",class:_vm.selectedCategory && !_vm.isUnordered ? 'enable-item' : 'disable-item',attrs:{"name":"cil-move","data-id":item.id}})],1)]}},{key:"index",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.index)+" ")])]}},{key:"menu_image",fn:function({item}){return [_c('td',[_c('CImg',{staticClass:"c-avatar-img",staticStyle:{"width":"36px","height":"36px"},attrs:{"src":item.menu_image_url,"placeholderColor":"lightgray"}})],1)]}},{key:"category_index",fn:function({item}){return [_c('td',[_vm._v(" "+_vm._s(item.category.index)+" ")])]}},{key:"category_name",fn:function({item}){return [_c('td',[_vm._v(" "+_vm._s(item.category.category_name)+" ")])]}},{key:"custom_id",fn:function({item}){return [_c('td',[_c('CInput',{staticClass:"mb-0",attrs:{"value":item.custom_id,"disabled":item.eol == 1},on:{"update:value":function($event){return _vm.$set(item, "custom_id", $event)},"input":(value) => {
            item._classes = 'table-warning';
            _vm.updateMenuItemCustomId(item, value);
          }}})],1)]}},{key:"price",fn:function({item}){return [_c('td',[_c('CInput',{staticClass:"mb-0",attrs:{"lazy":false,"value":item.price,"placeholder":"0.00","type":"number","step":"0.10","pattern":"^\\d+(?:\\.\\d{1,2})?$","onchange":"(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)","disabled":item.eol == 1},on:{"update:value":function($event){return _vm.$set(item, "price", $event)},"input":(value) => {
            item._classes = 'table-warning';
            _vm.updateMenuItemPrice(item, value);
          }},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{attrs:{"name":"cil-euro"}})]},proxy:true}],null,true)})],1)]}},(_vm.usetoprice)?{key:"toPrice",fn:function({item}){return [_c('td',[_c('CInput',{staticClass:"mb-0",attrs:{"lazy":false,"value":item.toPrice,"placeholder":"0.00","type":"number","step":"0.10","pattern":"^\\d+(?:\\.\\d{1,2})?$","onchange":"(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)","disabled":item.eol == 1 || _vm.samepricetoprice},on:{"update:value":function($event){return _vm.$set(item, "toPrice", $event)},"input":(value) => {
            item._classes = 'table-warning';
            _vm.updateMenuItemToPrice(item, value);
          }},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{attrs:{"name":"cil-euro"}})]},proxy:true}],null,true)})],1)]}}:null,{key:"menu_ext_group_count",fn:function({item}){return [_c('td',[_c('span',{staticClass:"count bg-dark"},[_vm._v(_vm._s(item.menugroups))])])]}},{key:"actions",fn:function({ item, index }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{staticClass:"enable-item",attrs:{"size":"sm","color":"info","shape":"pill","to":{ name: 'EditLinkedMenu', params: { id: item.id }}}},[_c('CIcon',{attrs:{"name":"cil-align-left"}})],1),_c('CButton',{class:{ 'disable-item': item.eol == 1 },attrs:{"size":"sm","color":"danger","shape":"pill","variant":"ghost"},on:{"click":function($event){return _vm.deleteItem(item, index)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}],null,true)}),_c('CToaster',{staticClass:"mb-5 mr-4",attrs:{"position":"bottom-right","autohide":3000}},_vm._l((_vm.toasts),function(toast){return _c('CToast',{key:'toast' + toast,attrs:{"show":true,"color":"warning"}},[_vm._v(" ⚠ "+_vm._s(_vm.toastMessage)+" ")])}),1),_c('CCard',{staticClass:"actions sticky-bottom"},[_c('CCardBody',{staticClass:"p-2"},[_c('div',{staticClass:"d-flex flex-wrap align-items-center",staticStyle:{"gap":"0.75rem"}},[_c('div',[_c('h5',{staticClass:"mt-1"},[_vm._v("Total: "),_c('span',{staticClass:"d-inline count bg-primary pb-1"},[_vm._v(_vm._s(_vm.total))])])]),(_vm.selectedCategory)?_c('div',[_c('CButton',{attrs:{"color":"info","size":"sm"},on:{"click":_vm.reIndex}},[_c('CIcon',{staticClass:"mb-1",attrs:{"name":"cil-list-numbered"}}),_vm._v(" Re-Index ")],1)],1):_vm._e(),(_vm.pages > 1)?_c('div',{staticClass:"ml-auto"},[_c('CPagination',{attrs:{"align":"center","dots":false,"pages":_vm.pages,"active-page":_vm.activePage},on:{"update:activePage":function($event){_vm.activePage=$event},"update:active-page":function($event){_vm.activePage=$event}}})],1):_vm._e()])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }