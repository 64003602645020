<template>
  <CCardBody>

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="d-flex flex-md-row flex-column" style="gap: 0.5rem;">
            <!-- Restaurant Filter -->
            <div class="flex-grow-1">
              <v-select
                class="v-select-filter"
                placeholder="Select category.."
                v-model="selectedCategory"
                :options="availableCategories"
                @input="categoryFilter"
              />
            </div>

            <!-- Pagination -->
            <div v-if="pages > 1">
              <CPagination
                align="end"
                :dots='false'
                :pages="pages"
                :active-page.sync="activePage"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CCard v-if="usetoprice" >
      <CCardBody class="p-2">
        <CInputCheckbox
          label="Same price toprice"
          :checked.sync="samepricetoprice"
          custom
        />
      </CCardBody>
    </CCard>

    <CDataTable
      size="sm"
      hover
      striped
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :table-filter="{
        label: 'Filter:',
        placeholder: '...',
        external: true,
        lazy: true
      }"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No linked menu items found!',
      }"
      @update:sorter-value="sorterValue"
      @update:table-filter-value="tableSearch"
      @update:column-filter-value="columnFilter"
      @pagination-change="paginationChange"
    >

      <!-- Move Index -->
      <template #move_index="{ item }">
        <td class="move-item text-center" @click="getSortingAlert">
          <CIcon name="cil-move"
            :data-id="item.id"
            class="align-bottom"
            :class="selectedCategory && !isUnordered ? 'enable-item' : 'disable-item'"
          />
        </td>
      </template>

      <!-- Index -->
      <template #index="{item}">
        <td class="text-center">
          {{ item.index }}
        </td>
      </template>

      <!-- Menu Image -->
      <template #menu_image="{item}">
        <td>
          <CImg
            :src="item.menu_image_url"
            class="c-avatar-img"
            style="width: 36px; height: 36px"
            placeholderColor="lightgray"
          />
        </td>
      </template>

      <!-- Category Index -->
      <template #category_index="{item}">
        <td>
          {{ item.category.index }}
        </td>
      </template>

      <!-- Category Name -->
      <template #category_name="{item}">
        <td>
          {{ item.category.category_name }}
        </td>
      </template>

      <!-- Custom ID -->
      <template #custom_id="{item}">
        <td>
          <CInput
            class="mb-0"
            :value.sync="item.custom_id"
            :disabled="item.eol == 1"
            @input="(value) => {
              item._classes = 'table-warning';
              updateMenuItemCustomId(item, value);
            }"
          />
        </td>
      </template>

      <!-- Price -->
      <template #price="{item}">
        <td>
          <CInput
            class="mb-0"
            :lazy="false"
            :value.sync="item.price"
            placeholder="0.00"
            type="number"
            step="0.10"
            pattern="^\d+(?:\.\d{1,2})?$"
            onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"
            :disabled="item.eol == 1"
            @input="(value) => {
              item._classes = 'table-warning';
              updateMenuItemPrice(item, value);
            }"
          >
            <template #prepend-content><CIcon name="cil-euro" /></template>
          </CInput>
        </td>
      </template>

       <!-- Takeout Price -->
       <template v-if="usetoprice" #toPrice="{item}">
        <td>
          <CInput
            class="mb-0"
            :lazy="false"
            :value.sync="item.toPrice"
            placeholder="0.00"
            type="number"
            step="0.10"
            pattern="^\d+(?:\.\d{1,2})?$"
            onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"
            :disabled="item.eol == 1 || samepricetoprice"
            @input="(value) => {
              item._classes = 'table-warning';
              updateMenuItemToPrice(item, value);
            }"
          >
            <template #prepend-content><CIcon name="cil-euro" /></template>
          </CInput>
        </td>
      </template>

      <!-- Menu Ext Group Count-->
      <template #menu_ext_group_count="{item}">
        <td>
          <span class="count bg-dark">{{item.menugroups}}</span>
        </td>
      </template>

      <!-- Actions -->
      <template #actions="{ item, index }">
        <td class="text-center">
          <!-- <CButton
            size="sm"
            color="success"
            shape="pill"
            class="ml-1"
            :variant="item.prices.length ? '' : 'ghost'"
            v-c-tooltip="{
              html: true,
              content: 'Prices'
            }"
            @click="showPrices(item)"
            ><CIcon name="cil-euro" />
          </CButton> -->

          <CButton
            class="enable-item"
            size="sm"
            color="info"
            shape="pill"
            :to="{ name: 'EditLinkedMenu', params: { id: item.id }}"
            ><CIcon name="cil-align-left" />
          </CButton>

          <CButton
            :class="{ 'disable-item': item.eol == 1 }"
            size="sm"
            color="danger"
            shape="pill"
            variant="ghost"
            @click="deleteItem(item, index)"
            ><CIcon name="cil-trash" />
          </CButton>
        </td>
      </template>
    </CDataTable>

    <CToaster position="bottom-right" :autohide="3000" class="mb-5 mr-4">
      <CToast v-for="toast in toasts" :key="'toast' + toast" :show="true" color="warning">
        &#9888; {{ toastMessage }}
      </CToast>
    </CToaster>

    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div v-if="selectedCategory">
            <CButton
              @click="reIndex"
              color="info"
              size="sm"
            >
              <CIcon name="cil-list-numbered" class="mb-1"/>&nbsp;Re-Index
            </CButton>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <!-- <ProductPrices :item.sync="selectedItem" /> -->

  </CCardBody>
</template>

<script>
import { mapState } from 'vuex'
import Sortable from "sortablejs";
import _debounce from 'lodash/debounce';
// import ProductPrices from "@/components/ProductPrices";

export default {
  props:{
    usetoprice:false,
  },
  name: "BackendTable",
  components: {
    // ProductPrices
  },
  data() {
    return {
      selectedItem: null,
      loadedItems: [],
      fields: [],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Menu Category Filter
      availableCategories: [],

      // Form değişikliklerini izlemek için
      unsavedChanges: false,

      // For sorting alerts
      isUnordered: false,
      toasts: 0,
      toastMessage: null,

      samepricetoprice:false,
    };
  },

  async mounted() {
    this.activePage = this.queryPage;
    this.fields = [
      { key: "move_index", label: "", filter: false, sorter: false, _style: "min-width: 40px; width: 40px;" },
      { key: "actions", label: "Actions", filter: false, sorter: false, _style: "min-width: 88px; width: 88px; text-align: center;" },
      { key: "index", label: "#Index", filter: false, _style: "min-width: 32px; width: 32px; text-align: center;" },
      { key: "menu_image", label: "", filter: false, sorter: false },
      { key: "menu_name", label: "Name", filter: false, _style: "width: 15%" },
      { key: "category_name", label: "Category", filter: false, _style: "width: 15%" },
      { key: "category_index", label: "#Cat. Index", filter: false, sorter: true, _style: "width: 5%" },
      { key: "description", label: "Description", filter: false, _style: "width: 25%" },
      { key: "custom_id", label: "Custom ID", filter: false, _style: "width: 8%" },
      { key: "price", label: "Price", filter: false, sorter: false, _style: "min-width: 150px" },
      { key: "toPrice", label: "Takeout Price", filter: false, sorter: false, _style: "min-width: 150px" },
      { key: "menu_ext_group_count", label: "Menu Groups", filter: false, sorter: true, _style: "min-width: 40px; width: 40px; text-align: center;" },
    ]

    if (!this.usetoprice) {
      this.fields = this.fields.filter((e) => e.key !== "toPrice");
    }


    await this.getAvailableCategories();

    if (this.categoryId) {
      this.selectedCategory = this.availableCategories.find(item => item.value == this.categoryId);
    }

    await this.getAllItems();

    //#region Sortablejs işlemleri
    var itemID, newIndex;
    var el = document.getElementsByTagName("tbody");

    var sortable = Sortable.create(el[0], {
      handle: ".move-item svg.c-icon",
      chosenClass: "table-selected",
      animation: 150,
      easing: "cubic-bezier(1, 0, 0, 1)",

      onSort: function (/**Event*/ evt) {
        var itemEl = evt.item; // dragged HTMLElement
        newIndex = evt.newIndex;
        itemID = itemEl.getElementsByTagName("svg")[0].getAttribute("data-id");
        // console.log(itemID, newIndex);
      },
      store: {
        /**
         * Save the order of elements. Called onEnd (when the item is dropped).
         * @param {Sortable}  sortable
         */
        set: () =>
          this.storeNewIndexes(
            parseInt(itemID),
            newIndex + ((this.activePage - 1) * this.itemsPerPage)
          ),
      },
    });
    //#endregion Sortablejs ---

    // F5 veya başka bir siteye gitmeden önce 'Yaptığınız değişiklikler kaydedilmemiş olabilir.' koruması için
    window.onbeforeunload = () => this.unsavedChanges === true ? true : null;

    this.$watch('loadedItems', (newV, oldV) => (this.unsavedChanges = true), {
      deep: true
    })
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: 'RestaurantLinkedMenus', params: { restaurant_id: this.restaurantId }, query: { page: this.activePage } });

      this.onTableChange();
    },
  },

  computed: {
    ...mapState(['filters']),

    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
    host() { return this.$backend.HOST; },
    restaurantId () { return this.$route.params.restaurant_id },
    categoryId () { return this.$route.query.category },
    selectedCategory: {
      get () { return this.filters.restaurants.linkedMenus.selectedCategory },
      set (value) { this.filters.restaurants.linkedMenus.selectedCategory = value },
    },
  },

  methods: {
    prepareToExit() {
      if (this.unsavedChanges === true) {
        return window.confirm('Do you really want to leave? You have unsaved changes!')
      }
      return true;
    },

    async getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.RESTAURANT_LINKED_MENUS.GET_ALL.replace("{restaurant_id}", this.restaurantId)),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,
          category: (this.selectedCategory && this.selectedCategory.value) ? this.selectedCategory.value : null,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== ""
        )
          url.searchParams.append(key, params[key]);
      });

      // Sortablejs işleminden sonra sayfa geçişlerinde index değerinde göre sıralanmış ögeler
      // düzensiz sırada geliyordu. Bunu önlemek için loadedItems dizisini sıfırlamak gerekti.
      // Sortablejs kullanılmayacaksa aşağıda satır kaldırılabilir.
      this.loadedItems = []

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;

            // Menuler düzensiz sıralanmış mı?
            this.isUnordered = this.loadedItems.some(item => item.index == null || item.index <= 0);

            Object.keys(items.data).map(function(key, index) {
              let item = items.data[index];
              item._classes = item.eol == 1 ? 'strikeout disable-item' : '';
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllItems();
    },
    tableSearch(search) {
      this.search = search;
      this.getAllItems();
    },
    columnFilter(filter) {
    },

    deleteItem(item, index) {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to delete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios
              .delete(
                this.$backend.RESTAURANT_LINKED_MENUS.DELETE.replace("{restaurant_id}", this.restaurantId).replace("{id}", item.id)
              )
              .then((response) => {
                if (response.data.soft === true) {
                  item._classes = 'strikeout disable-item';
                } else {
                  this.$delete(this.loadedItems, index);
                  this.total--;
                }

                this.showErrorAlert = false
                this.successAlertMessage = {itemName: item.menu_name, message: 'linked menu deleted.'}
                this.dismissSuccessAlert = this.dismissSecs
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data
                this.showErrorAlert = true
              })
              .finally(() => {
                window.scrollTo(0,0);
                this.loading = false;
              });
            // ---------------
          }
        },
      });
    },

    updateMenuItemPrice: _debounce(async function (item, value) {
      value && await this.updatePrice('price',item);

      if (this.usetoprice && this.samepricetoprice) {
        item.toPrice = item.price;
        value && await this.updatePrice('toPrice', item);
      }
    }, 1500),

    updateMenuItemToPrice: _debounce(function (item, value) {
      value && this.updatePrice('toPrice',item);
    }, 1500),

    updateMenuItemCustomId: _debounce(function (item, value) {
      value && this.updateCustomId(item, value)
    }, 1500),

    async updatePrice(el, item) {
      this.loading = true

      await this.$axios
        .patch(this.$backend.RESTAURANT_LINKED_MENUS.UPDATE_PRICE.replace("{restaurant_id}", this.restaurantId).replace("{id}", item.id), el === 'price' ? ({ newPrice: item.price }) : ({ newToPrice: item.toPrice }))
        .then(this.handleItemUpdateSuccess(item)) // Update UI and state on successful update
        .catch((error) => this.handleItemUpdateError(item, error))
        .finally(this.resetItemClassesAfterDelay(item))
    },
    async updateCustomId(item, value) {
      const url = this.$backend.RESTAURANT_LINKED_MENUS.UPDATE_CUSTOM_ID
        .replace("{restaurant_id}", this.restaurantId)
        .replace("{id}", item.id);

      await this.$axios.patch(url, { newCustomId: value })
        .then(this.handleItemUpdateSuccess(item)) // Update UI and state on successful update
        .catch((error) => this.handleItemUpdateError(item, error))
        .finally(this.resetItemClassesAfterDelay(item));
    },

    handleItemUpdateSuccess(item) {
      item._classes = 'table-success';
      this.showErrorAlert = false;
      this.unsavedChanges = false;
      this.$forceUpdate();
    },
    handleItemUpdateError(item, error) {
      window.scrollTo(0,0);
      item._classes = 'table-danger';
      this.errorAlertMessage = error.response.data
      this.showErrorAlert = true
      this.$forceUpdate();
    },
    resetItemClassesAfterDelay(item) {
      this.loading = false;
      setTimeout(() => {
        item._classes = '';
        this.$forceUpdate();
      }, 2000);
    },

    storeNewIndexes(itemID, newIndex) {
      if(isNaN(itemID) || isNaN(newIndex))
        return;

      this.loading = true;

      // Dizi index'i 0'dan başlıyor, ancak bizim 0. elemanın index'i 1'den başlıyor.
      newIndex++;

      // For Front-End
      var item = this.loadedItems.find(item => item.id == itemID);
      var oldIndex = item.index;
      if (newIndex > oldIndex) {
        this.loadedItems.filter((item) => {
          return item.index > oldIndex && item.index <= newIndex;
        }).forEach((value, index) => { value.index--; });
      }
      else if (newIndex < oldIndex) {
        this.loadedItems.filter((item) => {
          return item.index < oldIndex && item.index >= newIndex;
        }).forEach((value, index) => { value.index++; });
      }
      item.index = newIndex;

      // For Back-End
      this.$axios
        .get(this.$backend.RESTAURANT_LINKED_MENUS.STORE_NEW_INDEXES.replace("{restaurant_id}", this.restaurantId), {
          params: { itemID, newIndex: newIndex, restaurant_id: this.restaurantId, category_id: this.selectedCategory.value },
        })
        // .then(this.getAllItems)
        // .then((response) => {console.log(response.data);})
        .catch((error) => {
          this.errorAlertMessage = error.response.data;
          this.showErrorAlert = true;
        })
        .finally(() => {
          this.loading = false;
          this.unsavedChanges = false
        });
    },

    reIndex() {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you want to re-index all items?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios.get(this.$backend.RESTAURANT_LINKED_MENUS.RE_INDEX.replace("{restaurant_id}", this.restaurantId), {
              params: { category_id: this.selectedCategory.value },
            })
              .then((response) => {
                if (response.data) {
                  this.getAllItems();
                  this.successAlertMessage = { itemName: 'Success:', message: 'All items reordered successfully.' };
                  this.showErrorAlert = false;
                  this.dismissSuccessAlert = this.dismissSecs;
                }
                else {
                  this.errorAlertMessage = { 'No re-indexed': 'All items are already sorted correctly!' };
                  this.showErrorAlert = true;
                }
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data;
                this.showErrorAlert = true;
              })
              .finally(() => {
                this.loading = false;
                window.scrollTo(0,0);
              });
            // ---------------
          }
        },
      });
    },

    getSortingAlert() {
      if (!this.selectedCategory) {
        this.toastMessage = 'To sort, you must first apply a category filter!';
      } else if (this.isUnordered) {
        this.toastMessage = 'There are incorrectly indexed items! Please Re-Index first.';
      }
      this.toasts++;
    },

    showPrices(item) {
      this.selectedItem = item;
      this.$store.state.productPricesShow = true;
    },

    // Filters
    async getAvailableCategories() { // Called when mounted
      await this.$axios.get(this.$backend.RESTAURANT_LINKED_CATEGORIES.GET_ALL_BY_RESTAURANT_ID.replace("{restaurant_id}", this.restaurantId))
        .then((response) => (this.availableCategories = response.data));
    },

    categoryFilter() {
      this.getAllItems();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.selectedCategory = null;
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },
  },
};
</script>

<style>
.table th, .table td {
    vertical-align: inherit !important;
}
</style>
