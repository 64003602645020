<template>
  <CModal
    class="bulk-menu-update-modal"
    title="Bulk Menu Update"
    color="white"
    size="lg"
    :show.sync="$store.state.bulkMenuUpdateShow"
    :closeOnBackdrop="false"
  >
    <div class="d-flex flex-md-row flex-column flex-gap-1">
      <div class="flex-grow-1">
        <v-select
          class="v-select-filter"
          placeholder="Please select a category.."
          v-model="selectedCategory"
          :options="availableCategories"
          :loading="loading && !availableCategories.length"
          @input="getMenuItems"
        />
      </div>
    </div>

    <div class="d-flex flex-column flex-gap-2 menu-items">
      <strong v-if="selectedCategory && !menuItems.length && !loading" class="lead text-danger">
        🛈 There is no menu item in this category.
      </strong>

      <div v-else-if="selectedCategory && menuItems.length" class="flex-grow-1 table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th style="min-width: 40px; width: 40px;"><input type="checkbox" v-model="selectAllItems" @click="selectAll"></th>
              <th>Menu</th>
              <th style="min-width: 150px; width: 180px;">Delivery Price</th>
              <th v-if="usetoprice" style="min-width: 150px; width: 180px;">Takeout Price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="menuItem in menuItems" :key="menuItem.id">
              <td><input type="checkbox" v-model="selectedItems" :value="menuItem"></td>
              <td>{{ menuItem.menu_name }}</td>
              <td>
                {{ menuItem.price | toCurrency }}
                <template v-if="menuItem.price != menuItem.new_price">
                  → <strong class="text-success">{{ menuItem.new_price | toCurrency }}</strong>
                </template>
              </td>
              <td v-if="usetoprice">
                {{ menuItem.toPrice | toCurrency }}
                <template v-if="menuItem.toPrice != menuItem.new_toPrice">
                  → <strong class="text-success">{{ menuItem.new_toPrice | toCurrency }}</strong>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="sticky-bottom">
        <CTabs v-if="selectedItems.length" variant="tabs">
          <CTab title="Update Prices" class="aside-tab-item">
            <CForm class="d-flex flex-column flex-gap-2 px-2 pt-2 align-items-baseline">
              <div class="d-flex flex-column flex-md-row w-100">
                <div class="form-group w-100">
                  <label class="d-block">Updated Price:</label>
                  <CInputRadioGroup
                    name="updated_price"
                    :options="priceOptions"
                    :checked.sync="$v.form.prices.updated_price.$model"
                    custom
                    inline
                  />
                </div>

                <div class="form-group w-100" :class="{ 'disable-item': form.prices.price_adjustment === 'fixed_price' }">
                  <label class="d-block">Reference Price:</label>
                  <CInputRadioGroup
                    name="reference_price"
                    :options="priceOptions"
                    :checked.sync="$v.form.prices.reference_price.$model"
                    custom
                    inline
                  />
                </div>
              </div>

              <div class="d-flex flex-column flex-md-row w-100">
                <CSelect
                  class="w-100 mr-2"
                  label="Price Adjustment"
                  placeholder="Please select.."
                  :value.sync="$v.form.prices.price_adjustment.$model"
                  :options="[
                    {value: 'fixed_price', label: 'Fixed Price' },
                    {value: 'fixed_adjustment', label: 'Fixed Adjustment' },
                    {value: 'percentage_adjustment', label: 'Percentage Adjustment' },
                  ]"
                />

                <CInput
                  class="w-100"
                  label="Value"
                  placeholder="0.00"
                  :value.sync="$v.form.prices.value.$model"
                  type="number"
                  step="0.01"
                  pattern="^\d+(?:\.\d{1,2})?$"
                >
                  <template #prepend-content>
                    <strong v-if="form?.prices?.price_adjustment === 'percentage_adjustment'">%</strong>
                    <CIcon v-else name="cil-euro" />
                  </template>
                </CInput>
              </div>

              <CButton @click="updatePrices" color="primary" shape="pill" :disabled="submitted">
                <CIcon name="cil-send" /> Update
              </CButton>
            </CForm>
          </CTab>
        </CTabs>
      </div>
    </div>

    <CElementCover :opacity="0.4" v-show="loading || submitted" />

    <template #footer class="p-1">
      <CButton @click="$store.commit('toggle', 'bulkMenuUpdateShow')" color="link">Close</CButton>
    </template>
  </CModal>

</template>

<script>
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate"
import { required, requiredIf } from "vuelidate/lib/validators"

const notZero = value => value !== 0;

export default {
  name: "BulkMenuUpdate",
  props: {
    restaurant: {
      type: Object, required: true,
      validator: function (value) {
        return 'id' in value;
      }
    },
  },
  data() {
    return {
      menuItems: [],
      selectedItems: [],
      availableCategories: [],

      form: {},
      selectAllItems: false,
      loading: false,
      submitted: false,
      errorAlertMessage: '',
    };
  },

  watch: {
    async reloadParams() {
      this.loading = true;
      if (this.$store.state.bulkMenuUpdateShow) {

        this.menuItems = [];
        this.selectedItems = [];
        this.availableCategories = [];

        await this.getAvailableCategories();

        if (this.selectedCategory) {
          await this.getMenuItems();
        }

        this.form = {
          prices: {
            updated_price: 'price',
            reference_price: null,
            price_adjustment: 'fixed_price',
            value: null,
          },
        };

        this.$watch('form.prices', { handler: 'calculatePrices', deep: true });
        // this.$watch('selectedItems', { handler: 'calculatePrices' });
      }
      this.loading = false;
    },

    'form.prices.price_adjustment': function(newVal, oldVal) {
      if (newVal === 'fixed_price') {
        this.form.prices.reference_price = null;
      } else if (!this.usetoprice) {
        this.form.prices.reference_price = 'price';
      }
    },
  },

  computed: {
    ...mapGetters(["errors"]),
    ...mapState(['filters']),

    reloadParams() { return [this.$store.state.bulkMenuUpdateShow]; },
    usetoprice() { return this.restaurant?.usetoprice || false },
    priceOptions() {
      return [
        { value: 'price', label: 'Delivery' },
        ...(this.usetoprice ? [{ value: 'toPrice', label: 'Takeout' }] : [])
      ];
    },
    selectedCategory: {
      get() { return this.filters.restaurants.linkedMenus.selectedCategory },
      set(value) { this.filters.restaurants.linkedMenus.selectedCategory = value },
    },
  },

  mixins: [validationMixin],
  validations: {
    form: {
      prices: {
        updated_price: { required },
        reference_price: {
          required: requiredIf(function () {
            return this.form?.prices?.price_adjustment !== 'fixed_price';
          })
        },
        price_adjustment: { required },
        value: { required, notZero },
      },
    }
  },

  methods: {
    checkIfValid(fieldName) {
      const fields = fieldName.split(".");
      const field = fields.length > 1
        ? this.$v.form[fields[0]][fields[1]]
        : this.$v.form[fieldName];

      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === "");
    },

    formIsValid() {
      if (this.$v.form.$invalid) {
        this.$v.$touch();

        const validationMessages = {
          reference_price: 'The reference price is required!',
          value: 'The value is required!',
        };

        Object.keys(validationMessages).forEach(field => {
          if (this.$v.form.prices[field].$invalid) {
            this.$toast.error(validationMessages[field]);
          }
        });
      }

      return !this.$v.form.$invalid;
    },

    async getAvailableCategories() {
      try {
        const response = await this.$axios.get(
          this.$backend.RESTAURANT_LINKED_CATEGORIES.GET_ALL_BY_RESTAURANT_ID.replace("{restaurant_id}", this.restaurant.id)
        );
        this.availableCategories = response.data;
      } catch (error) {
        this.availableCategories = [];
      }
    },

    async getMenuItems() {
      this.menuItems = [];
      this.selectedItems = [];
      this.selectAllItems = false;

      if (!this.selectedCategory) {
        return;
      }

      this.loading = true;

      var url = new URL(this.$backend.RESTAURANT_LINKED_MENUS.LIST.replace("{restaurant_id}", this.restaurant.id)),
        params = {
          category_id: this.selectedCategory?.value,
          is_active: true
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== ""
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          this.menuItems = response.data.map((el) => {
            const { menu_name, id, price, toPrice } = el;
            return {
              menu_name,
              id,
              price,
              toPrice,
              new_price: price,
              new_toPrice: toPrice,
            };
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    selectAll(event) {
      this.selectedItems = event.target.checked ? this.menuItems.map(item => item ) : [];
    },

    calculatePrices(obj) {
      // Reset prices
      this.menuItems.forEach(item => {
        item.new_price = item.price;
        item.new_toPrice = item.toPrice;
      });

      if (this.$v.form.$invalid || this.selectedItems.length === 0) {
        return;
      }

      const updatePrice = (item, adjustment) => {
        let newPrice = 0;
        const referencePrice = item[`new_${obj.reference_price}`];

        switch (obj.price_adjustment) {
          case 'fixed_price':
            newPrice = adjustment;
            break;
          case 'fixed_adjustment':
            newPrice = referencePrice + adjustment;
            break;
          case 'percentage_adjustment':
            newPrice = referencePrice * (1 + adjustment / 100);
            break;
        }

        item[`new_${obj.updated_price}`] = Math.floor(newPrice * 20) / 20; // Sayısı en yakın .05 değerine aşağı yuvarla
      };

      const adjustmentValue = parseFloat(obj.value);
      this.selectedItems.forEach(item => updatePrice(item, adjustmentValue));
    },

    async updatePrices() {
      if (!this.formIsValid()) {
        return;
      }

      this.submitted = true;

      try {
        const { data } = await this.$axios.put(
          this.$backend.RESTAURANT_LINKED_MENUS.BULK.UPDATE_PRICES.replace("{restaurant_id}", this.restaurant.id),
          {
            items: this.selectedItems.pluck('id').join(','),
            prices: this.form.prices,
          }
        );

        this.$emit('updated', { prices: data });
        this.$store.commit('set', ['bulkMenuUpdateShow', false]);
        this.$toast.success(data.message);
      } catch (error) {
        const errorMessage = typeof error.response?.data?.errors === 'object'
          ? Object.values(error.response.data.errors).join(', ')
          : error.message || 'An error occurred';
        this.$toast.error(errorMessage);
      } finally {
        this.submitted = false;
      }
    },
  }
};
</script>

<style>
.bulk-menu-update-modal .modal-header {
  padding: 0.75rem !important;
}

.bulk-menu-update-modal .modal-footer {
  padding: 0.25rem !important;
}

.bulk-menu-update-modal .menu-items {
  margin-top: 1rem;
  min-height: 300px;
  max-height: calc(100vh - 232px);
}

@media (max-width: 576px) {
  .bulk-menu-update-modal .menu-items {
    max-height: calc(100vh - 190px);
  }
}
</style>
